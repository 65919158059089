.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .form-group {
    margin-bottom: 15px;
    padding-right: 18px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    /* padding-right: 18px; */
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    /* background-color: #007bff; */
    background-color: #d10000ec;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    /* background-color: #0056b3; */
    background-color: #83070d;
  }

  .toggle-link {
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
  }

  .toggle-link:hover {
    color: #0056b3;
  }

  .toggle-link:active {
    color: #0056b3;
  }
  
  .toggle-link:visited {
    color: #0056b3;
  }

  .toggle-link:focus {
    color: #0056b3;
  }

  .validation-error {
    color: #dc3545;
    font-size: 0.8rem;
    margin-top: 0.25rem;
  }

  .input-error {
    border-color: #dc3545 !important;
  }

  .input-error:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .validation-error:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .error-message {
    color: #dc3545;
    background-color: #ffe6e6;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    text-align: center;
  }

  .success-message {
    color: #28a745;
    background-color: #e8f5e9;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    text-align: center;
  }

  .success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }

  .success-container .success-message {
    font-size: 1.5rem;
    padding: 2rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    animation: fadeIn 0.5s ease-in;
  }

  @keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
  }

  .login-link {
    margin-top: 1rem;
    text-align: center;
  }

  .login-link a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }

  .login-link a:hover {
    color: #0056b3;
    text-decoration: underline;
  }


