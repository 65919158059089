.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* background-color: white; or your preferred color */
    z-index: 1000; /* ensures header stays above other content */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* optional: adds subtle shadow */
  }
  
  /* Your existing header styles */
  .fixed-header nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .fixed-header nav li {
    padding: 15px;
  }
  
  .fixed-header nav a {
    text-decoration: none;
    /* color: #333; */
  }
  
  .fixed-header .logo {
    padding: 1px;
  }